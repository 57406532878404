import * as React from "react"

const LightningSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm-.5 15.9v-4.1H8.8c-.4 0-.6-.4-.4-.7l3.7-7.2c.2-.5.9-.4.9.2v4.2h2.5c.4 0 .6.4.4.7l-3.6 7.1c-.1.5-.8.3-.8-.2z"
    />
  </svg>
)
export default LightningSvg
