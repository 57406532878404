import React, { FC } from "react"
import Modal from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import OfflineEntryForm, { IOfflineEntryFormProps } from "./OfflineEntryForm"

interface IOfflineEntryModalProps extends IOfflineEntryFormProps {
  isOpen: boolean
}

const OfflineEntryModal: FC<IOfflineEntryModalProps> = ({ isOpen, ...rest }) => {
  return (
    <Modal isOpen={isOpen} padded={false} afterClose={rest.onCancel} onBackgroundClick={rest.onCancel} onEscapeKeydown={rest.onCancel}>
      <OfflineEntryForm {...rest} />
    </Modal>
  )
}

export default OfflineEntryModal
