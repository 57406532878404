import { fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled from "styled-components/macro"
import { fontWeight, palette } from "../../../utils/style-utils"

export const MultipleEntriesManagePlayerContent = styled.div`
  display: flex;
  flex-direction: column;
  background: ${palette.white};
  border: 1px solid ${palette.gray90};
  box-sizing: border-box;
  border-top: none;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  overflow: hidden;
`
export const MultipleEntriesManagePlayerHeader = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-top: none;
  padding: 0 1.5rem;

  & > .player-header-title {
    font-family: ${fontFamily.base};
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0px;
    text-align: left;
    color: ${palette.gray20};
    padding: 1.25rem 0;
    text-transform: uppercase;
  }

  & > .player-header-subtitle {
    font-family: ${fontFamily.base};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: -0.1px;
    text-align: left;
    color: ${palette.gray40};
  }
  & > .player-header-actions {
    display: flex;
    flex-direction: row-reverse;
    box-sizing: border-box;
    border-top: none;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;

    & > .player-header-actions-search {
      display: flex;
      box-sizing: border-box;
    }
    & > .action-buttons {
      display: flex;
      & > .email-pool-cta {
        margin: 0 1rem;
      }
    }
  }
`

export const MultipleEntriesManagePlayerStickyTable = styled.div`
  flex: none;
  min-width: 100%;

  & > .multiple-entries-players-section-tile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    color: ${palette.gray20};

    & > .section-title {
      font-family: ${fontFamily.base};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${fontWeight.bold};
      line-height: 1;
      letter-spacing: -0.1px;
      text-align: left;
    }
    & > .pagination-info {
      font-family: ${fontFamily.base};
      font-style: normal;
      font-weight: ${fontWeight.regular};
      letter-spacing: -0.1px;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
    }
  }

  & > .multiple-entries-players-table-header {
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;

    & > span {
      font-family: ${fontFamily.condensed};
      font-size: 0.75rem;
      font-style: normal;
      font-weight: ${fontWeight.medium};
      line-height: 1;
      letter-spacing: 1.25px;
      text-align: left;
      color: ${palette.gray50};
      text-transform: uppercase;
    }
    & > .player-name {
      flex: 2;
      padding-right: 1rem;
    }
    & > .player-email {
      flex: 4;
    }
    & > .table-header-actions {
      flex: 3;

      & > .player-is-manager {
        width: 4rem;
        text-align: right;
        display: block;
      }
    }
  }
`
export const MultipleEntriesPlayerRowWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border-top: 1px solid ${palette.gray90};
  background-color: ${palette.white};
  min-width: 100%;
  width: fit-content;
  position: relative;
  overflow: visible;
  padding: 0 1.5rem;
  height: 3rem;
  box-sizing: border-box;
  font-family: ${fontFamily.base};
  font-size: 14px;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: -0.1px;
  font-weight: ${fontWeight.regular};
  text-align: left;
  align-items: center;
  color: ${palette.gray20};

  & > .player-name {
    flex: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    padding-right: 1rem;
  }
  & > .player-email {
    font-weight: ${fontWeight.bold};
    flex: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & > .actions-on-player {
    font-weight: 400;
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .player-is-manager {
      width: 4rem;
      text-align: right;
    }
  }

  &.is-my-entry:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0.25rem;
    background-color: ${palette.lightBlue3};
    border-radius: 0 0.25rem 0.25rem 0;
  }
`
