import styled from "styled-components/macro"
import { palette, pxToRem } from "../../../utils/style-utils"
import { HorizontalOverflowContainer } from "../../../components/OverflowContainer"

export const StickyTableXWrapper = styled(HorizontalOverflowContainer)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  justify-content: flex-start;

  overflow-y: visible;
  border: ${pxToRem(1)} solid ${palette.poolPageBorder};
  border-left: none;
  border-right: none;
`
