import { defaultDataIdFromObject, useMutation, useQuery, useLazyQuery, MutationFunctionOptions } from "@apollo/client"
import React, { ReactNode, ReactText, useState } from "react"
import { SetEntryRolesMutation, SetEntryRolesMutationVariables } from "../../../../../__generated__/SetEntryRolesMutation"
import constants from "../../../../../common/constants"
import { TPoolRouteProps } from "../../../../../routes.d"
import ErrorView from "../../../../components/ErrorView"
import useControls from "../../../../hooks/useControls"
import Analytics from "../../../../utils/analytics"
import { edgeToNode, getQueryWithError, getScrollContainerEl } from "../../../../utils/misc-utils"
import {
  BRACKET_MANAGE_PLAYERS_QUERY,
  LEAVE_POOLS_MUTATION,
  SET_ENTRY_ROLES_MUTATION,
  TOGGLE_SILENCE_USER_FOR_POSTING,
  PLAYER_BRACKETS_QUERY,
} from "../../../queries"
import PaginationNew from "../../components/PaginationNew"
import { StandardPoolPageContainer } from "../../components/PoolPage"
import { StickyTableXWrapper } from "../../components/StickyTable"
import {
  MultipleEntriesManagePlayerContent,
  MultipleEntriesManagePlayerHeader,
  MultipleEntriesManagePlayerStickyTable,
  MultipleEntriesPlayerRowWrap,
} from "../../styles/ManagePlayers.styles"
import { emptyObject, emptyArray } from "../../../../../common/misc-utils"
import {
  BracketManagePlayersQuery,
  BracketManagePlayersQueryVariables,
  BracketManagePlayersQuery_pool_players_edges_node,
} from "../../../../../__generated__/BracketManagePlayersQuery"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { SearchComponent } from "@cbs-sports/sports-shared-client/build/cjs/components/Search"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import LightningSvg from "../../../../components/icons/Lightning"
import MoreSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/More"
import Menu, { MenuItem, MenuLink } from "@cbs-sports/sports-shared-client/build/cjs/components/Menu"
import { ANCHORS } from "@cbs-sports/sports-shared-client/build/cjs/components/Popover"
import { buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import HorizontalSeparator from "@cbs-sports/sports-shared-client/build/cjs/components/HorizontalSeparator"
import Modal from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import ConfirmDialog from "../../../../components/ConfirmDialog"
import OfflineEntryModal from "../../components/MultipleEntriesArea/OfflineEntryModal"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast"
import { withoutDomain } from "../../../../../common/url-utils"
import { useHistory } from "react-router-dom"
import { LeavePools, LeavePoolsVariables } from "../../../../../__generated__/LeavePools"
import EmailPoolModal from "../../components/MultipleEntriesArea/EmailPoolModal"
import { EmailSvg, InfoCircle } from "@cbs-sports/sports-shared-client/build/cjs/components/icons"
import {
  ToggleSilenceUserForPostingMutation,
  ToggleSilenceUserForPostingMutationVariables,
} from "../../../../../__generated__/ToggleSilenceUserForPostingMutation"
import EditPlayerBracketModal, { PlayerBracketType } from "../../components/EntryModals/EditPlayerBracketModal"
import { PlayerBracketsQuery, PlayerBracketsQueryVariables } from "../../../../../__generated__/PlayerBracketsQuery"
import { trackActionByKey } from "../MessageBoard/message-board-tracking"
import { UpsertEntryMutation_upsertEntry_entry } from "../../../../../__generated__/UpsertEntryMutation"
import { TooltipBody } from "../EntryRow/EntryRow.styles"
import ClassicTooltip from "@cbs-sports/sports-shared-client/build/cjs/components/ClassicTooltip"
import { palette } from "../../../../utils/style-utils"
import { canUseDom } from "@cbs-sports/sports-shared-client/build/cjs/utils/dom-utils"

const toggleAccess = async (memberId, isManager, setEntryRolesMutation) => {
  const prefix = isManager ? "remove manager status" : "add manager status"
  Analytics.trackInteraction(`${prefix} - submit`)
  const result = await setEntryRolesMutation({
    variables: { memberId, roles: isManager ? [] : [constants.POOL_ROLES.MANAGER] },
    refetchQueries: ["BracketManagePlayersQuery"],
  }).then(
    () => {
      toast.snackbar(`Manager access ${isManager ? "removed" : "granted"}`)
      Analytics.trackInteraction(`${prefix} - success`)
    },
    () => Analytics.trackInteraction(`${prefix} - fail`),
  )
  return result
}

const toggleBlockAuthor = async (userId: string, poolId: string, canPostMessages: boolean, toggleSilenceUserForPostingMutation) => {
  const prefix = canPostMessages ? "block user for posting" : "unblock user for posting"
  Analytics.trackInteraction(`${prefix} - submit`)
  const result = await toggleSilenceUserForPostingMutation({
    variables: { userId, poolId },
    refetchQueries: ["BracketManagePlayersQuery"],
  }).then(
    () => {
      toast.snackbar(`Message board posting privileges have been ${canPostMessages ? "removed" : "granted"}`)
      Analytics.trackInteraction(`${prefix} - success`)
    },
    () => Analytics.trackInteraction(`${prefix} - fail`),
  )
  return result
}

interface ManagePlayersRowProps {
  member: BracketManagePlayersQuery_pool_players_edges_node
  setEntryRolesMutation: (options?: MutationFunctionOptions<SetEntryRolesMutation, SetEntryRolesMutationVariables> | undefined) => Promise<any>
  toggleSilenceUserForPostingMutation: (
    options?: MutationFunctionOptions<ToggleSilenceUserForPostingMutation, ToggleSilenceUserForPostingMutationVariables> | undefined,
  ) => Promise<any>
  onRemovePlayer: (userId: string, isEntryFromLoggedInUser: boolean, playerName: string) => void
  currentIsManager?: boolean
  showIsMine: boolean
  canLeavePool: boolean
  onMakePicksForPlayer: (member: BracketManagePlayersQuery_pool_players_edges_node) => void
  onAfterCreateEntryForPlayer: (entry: UpsertEntryMutation_upsertEntry_entry) => Promise<void>
  poolData: any
}
const ManagePlayersRow = ({
  member,
  setEntryRolesMutation,
  toggleSilenceUserForPostingMutation,
  onRemovePlayer,
  currentIsManager,
  showIsMine,
  canLeavePool,
  onMakePicksForPlayer,
  onAfterCreateEntryForPlayer,
  poolData,
}: ManagePlayersRowProps) => {
  const [moreElement, setMoreElement] = React.useState<null | HTMLElement>(null)
  const [loadingKey, setLoadingKey] = React.useState("")
  const { isInComingSoon } = poolData
  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreElement(event.currentTarget)
  }

  const handleMoreClose = () => {
    setMoreElement(null)
  }

  const onToggleAccessClick = () => {
    handleMoreClose()
    toggleAccess(member.id, isManager, setEntryRolesMutation)
  }

  const onMakePicksForPlayerClick = (member: BracketManagePlayersQuery_pool_players_edges_node) => {
    if (typeof onMakePicksForPlayer === "function") {
      onMakePicksForPlayer(member)
    }
    handleMoreClose()
  }

  const onToggleBlockAuthorClick = () => {
    const { poolId } = poolData || emptyObject
    const { userInfo, canPostMessages } = member
    const { id: userId } = userInfo
    handleMoreClose()
    toggleBlockAuthor(userId, poolId, canPostMessages, toggleSilenceUserForPostingMutation)
    trackActionByKey(canPostMessages ? "manage-players-mute" : "manage-players-unmute")
  }

  const handleAddNewEntryForUser = () => {
    const { userInfo } = member
    const { id: userId } = userInfo
    const { poolId, upsertEntryMutation } = poolData

    setLoadingKey("CreateBracket")
    upsertEntryMutation({
      variables: {
        poolId,
        forUserId: userId,
      },
    })
      .then((res) => {
        if (typeof onAfterCreateEntryForPlayer === "function") {
          const entry = res.data?.upsertEntry?.entry || emptyObject
          onAfterCreateEntryForPlayer(entry)
        }
        Analytics.trackInteraction("Admin new entry - success", { createEntry: true })
      })
      .catch((err) => {
        Analytics.trackInteraction("Admin new entry - fail")
      })
      .finally(() => {
        setLoadingKey("")
      })
  }

  const isManager = member.roles.indexOf(constants.POOL_ROLES.MANAGER) > -1
  const { hideEmail, email, userInfo, isMine, isOfflineEntry, canAddEntry, canPostMessages, hasMadeAPick } = member
  const playerName = userInfo.preferredEntryName || `${userInfo.firstName} ${userInfo.lastName}`
  const handleRemovePlayer = (playerId: string) => {
    handleMoreClose()
    onRemovePlayer(playerId, member.isMine, playerName)
  }

  const disableMore = isMine && !canLeavePool
  const canBeRemoved = !hasMadeAPick
  const renderBody = (body: ReactNode) => <TooltipBody>{body}</TooltipBody>
  const removePlayerDisabled = (
    <ClassicTooltip
      content={
        "This player has already made picks and cannot be removed. If you would like to remove this player, go to Edit/View Bracket(s) and clear the player's picks."
      }
      renderBody={renderBody}
      anchor={ANCHORS.TOP_CENTER}
    >
      <MenuItem disabled>
        <MenuLink>
          Remove player from pool
          <InfoCircle fontSize="10px" color={palette.gray75} style={{ marginLeft: "6px" }} />
        </MenuLink>
      </MenuItem>
    </ClassicTooltip>
  )

  return (
    <MultipleEntriesPlayerRowWrap
      className={cx({
        "is-my-entry": isMine && showIsMine,
      })}
    >
      <div className="player-name">{userInfo?.preferredEntryName}</div>
      <div className="player-email">{!hideEmail || currentIsManager ? email || "—" : "**********"}</div>
      <div className="actions-on-player">
        <span className="player-is-manager">{isManager ? "Yes" : "—"}</span>
        {currentIsManager && !disableMore && (
          <>
            <Button variant="clear" onClick={handleMoreClick} withoutText>
              <ListItemIcon size="lg">
                <MoreSvg />
              </ListItemIcon>
            </Button>
            <Menu anchorEl={moreElement} onClose={handleMoreClose} open={Boolean(moreElement)} origin="left" anchor={ANCHORS.BOTTOM_RIGHT}>
              <MenuItem disabled={isOfflineEntry}>
                <MenuLink onClick={onToggleAccessClick}>{`${isManager ? "Remove" : "Grant"} manager access`} </MenuLink>
              </MenuItem>
              <MenuItem disabled={isInComingSoon || isMine}>
                <MenuLink onClick={() => onMakePicksForPlayerClick(member)}>Edit/View Bracket(s)</MenuLink>
              </MenuItem>
              <MenuItem disabled={!canAddEntry || isMine || loadingKey === "CreateBracket"}>
                <MenuLink onClick={handleAddNewEntryForUser}>Create new bracket for player</MenuLink>
              </MenuItem>
              <MenuItem disabled={isOfflineEntry || isMine}>
                <MenuLink onClick={onToggleBlockAuthorClick}>{`${canPostMessages ? "Mute" : "Unmute"} from Message Board`} </MenuLink>
              </MenuItem>
              <HorizontalSeparator />
              {canBeRemoved ? (
                <MenuItem>
                  <MenuLink onClick={() => handleRemovePlayer(member.userId)}>Remove player from pool</MenuLink>
                </MenuItem>
              ) : (
                removePlayerDisabled
              )}
            </Menu>
          </>
        )}
      </div>
    </MultipleEntriesPlayerRowWrap>
  )
}

const stylez: React.CSSProperties = {
  overflow: "visible",
  border: "none",
}

const PoolPlayersHeader = ({
  poolId,
  isManager,
  onSearch,
  showEmailModal,
}: {
  poolId: string
  isManager?: boolean
  onSearch?: (text: string) => void
  showEmailModal: () => void
}) => {
  const [showModal, setShowModal] = useState(false)
  const title = isManager ? "Manage Players" : "Players"
  const hide = () => {
    setShowModal(false)
  }
  const show = () => {
    setShowModal(true)
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // when clearing the search term update the list
    if ((event?.target?.value || "").length === 0 && onSearch) {
      onSearch("")
    }
  }

  const onOfflineComplete = () => {
    hide()
    toast.snackbar("Offline player added")
  }
  return (
    <MultipleEntriesManagePlayerHeader>
      <span className="player-header-title">{title}</span>
      {isManager && (
        <>
          <span className="player-header-subtitle">
            Granting Manager Access enables that user to make changes to picks for ANY OTHER USER IN THE POOL, even after the tournament has started.
          </span>
          <div className="player-header-actions">
            <div className="player-header-actions-search">
              <SearchComponent
                searchOnEnter
                onSearch={onSearch}
                onChange={handleOnChange}
                btnText="Search"
                placeholder="Search Player"
                minLength={constants.ENTRY_NAME_MIN_LENGTH}
                data-cy="search_player"
              />
            </div>
            <div className="action-buttons">
              <Button variant="secondary" onClick={show} data-cy="add_offline_player">
                <ListItemIcon alignment="left">
                  <LightningSvg />
                </ListItemIcon>
                Add Offline Player
              </Button>

              <Button variant="secondary" onClick={showEmailModal} className="email-pool-cta" data-cy="email_pool">
                <ListItemIcon alignment="left">
                  <EmailSvg />
                </ListItemIcon>
                Email Pool
              </Button>
            </div>
          </div>
        </>
      )}
      <OfflineEntryModal isOpen={showModal} onCancel={hide} onComplete={onOfflineComplete} poolId={poolId} />
    </MultipleEntriesManagePlayerHeader>
  )
}

function MultipleEntriesManagePlayersWrapper(props: TPoolRouteProps) {
  const [searchTerm, setSearchTerm] = React.useState("")
  const [userIdToRemove, setUserIdToRemove] = React.useState("")
  const [playerNameToRemove, setPlayerNameToRemove] = React.useState("")
  const [isRemovingLoggedInUser, setIsRemovingLoggedInUser] = React.useState(false)
  const [showEmailPoolModal, setShowEmailPoolModal] = React.useState(false)
  const [emailSentToast, setEmailSendToast] = React.useState<ReactText>("")
  const { poolData } = props
  const { poolId, detailedEntry, toggleManagerMode, segmentForArea } = poolData
  const controls = useControls(props)
  const baseUrl = (segmentForArea && withoutDomain(segmentForArea.baseUrl)) || "/"
  const history = useHistory()
  const [leavePoolMutation, leavePoolMutationRes] = useMutation<LeavePools, LeavePoolsVariables>(LEAVE_POOLS_MUTATION, {
    refetchQueries: ["BracketManagePlayersQuery", "PoolSeasonStandingsQuery", "PoolDetailsQuery", "CentralCurrentUsersEntriesQuery"],
    awaitRefetchQueries: true,
  })

  const [playerAvailableBrackets, setPlayerAvailableBrackets] = useState<Array<PlayerBracketType> | null>(null)

  const playerBracketsQueryCompleted = React.useCallback(
    (data: PlayerBracketsQuery) => {
      const bracketNodes = data.pool.entries.edges.map(edgeToNode)
      const bracketCount = bracketNodes.length
      if (bracketCount <= 0) {
        return
      }
      if (bracketCount === 1) {
        //only one option. Skip modal and navigate to bracket page

        toggleManagerMode(true, () => {
          if (canUseDom) {
            window.location.href = withoutDomain(bracketNodes[0].url)
          }
        })
        return
      }

      //show modal for the manager to select entry to view/edit
      setPlayerAvailableBrackets(bracketNodes)
    },
    [history, poolId, toggleManagerMode],
  )

  const [getPlayerBrackets] = useLazyQuery<PlayerBracketsQuery, PlayerBracketsQueryVariables>(PLAYER_BRACKETS_QUERY, {
    onCompleted: playerBracketsQueryCompleted,
    fetchPolicy: "network-only",
  })

  const onRemovePlayer = (userId: string, isEntryFromLoggedInUser: boolean, playerName: string) => {
    setUserIdToRemove(userId)
    setPlayerNameToRemove(playerName)
    setIsRemovingLoggedInUser(isEntryFromLoggedInUser)
  }

  const cancelRemovePlayer = () => {
    setUserIdToRemove("")
    setPlayerNameToRemove("")
    setIsRemovingLoggedInUser(false)
  }

  const onMakePicksForPlayer = (entry: BracketManagePlayersQuery_pool_players_edges_node) => {
    toggleManagerMode(true)
    getPlayerBrackets({
      variables: {
        poolId: entry.poolId,
        userId: entry.userId,
      },
    })
  }

  const removePlayer = async () => {
    Analytics.trackInteraction("remove player - submit")

    await leavePoolMutation({
      variables: { poolIds: [poolId], userId: userIdToRemove },
      update: (cache) => {
        if (isRemovingLoggedInUser) {
          const userTeamsKey = defaultDataIdFromObject({ __typename: "UserTeams", id: "current" })
          cache.evict({ id: userTeamsKey })
        }
      },
    }).then(
      () => {
        Analytics.trackInteraction(`remove player - success`)
        if (isRemovingLoggedInUser) {
          history.push(baseUrl)
        }
        toast.snackbar("Player removed from pool")
      },
      () => Analytics.trackInteraction(`remove player - fail`),
    )
  }

  const confirmRemovePlayer = async () => {
    await removePlayer()
    setTimeout(() => {
      setUserIdToRemove("")
      setIsRemovingLoggedInUser(false)
    }, 300)
  }

  const [setEntryRolesMutation] = useMutation<SetEntryRolesMutation, SetEntryRolesMutationVariables>(SET_ENTRY_ROLES_MUTATION, {
    refetchQueries: ["CurrentMembershipQuery"],
    awaitRefetchQueries: true,
    fetchPolicy: "no-cache",
  })

  const [toggleSilenceUserForPostingMutation] = useMutation<ToggleSilenceUserForPostingMutation, ToggleSilenceUserForPostingMutationVariables>(
    TOGGLE_SILENCE_USER_FOR_POSTING,
  )

  const variables: BracketManagePlayersQueryVariables = {
    poolId: poolId || "",
    first: controls.first,
    after: controls.after,
    searchTerm,
  }
  const skip = !variables.poolId
  const managePlayersQuery = useQuery<BracketManagePlayersQuery, BracketManagePlayersQueryVariables>(BRACKET_MANAGE_PLAYERS_QUERY, {
    variables,
    skip,
  })

  const onAfterCreateEntryForPlayer = async (entry: UpsertEntryMutation_upsertEntry_entry) => {
    const { url } = entry
    toggleManagerMode(true, () => {
      if (canUseDom) {
        window.location.href = withoutDomain(url)
      }
    })
  }

  const onGoToPage = (cursor: any) => {
    controls.goToPage(cursor)
    const el = getScrollContainerEl()
    if (el === document.body || !el) {
      if (window && typeof window.scrollTo === "function") {
        window.scrollTo(0, 0)
      }
    } else {
      if (typeof el.scroll === "function") {
        el.scroll({ top: 0, left: 0, behavior: "instant" as any })
      }
    }
  }

  const withError = getQueryWithError([managePlayersQuery])
  if (withError) {
    return <ErrorView error={withError.error} refetch={withError.refetch} />
  }
  const isLoading =
    (leavePoolMutationRes && leavePoolMutationRes.loading) ||
    (!!managePlayersQuery && managePlayersQuery.hasOwnProperty("networkStatus") && managePlayersQuery.networkStatus < 3)
  const pageInfo = managePlayersQuery.data?.pool.players.pageInfo || emptyObject
  const { totalCount } = pageInfo

  const members = ((!isLoading && managePlayersQuery.data?.pool.players.edges) || emptyArray).map(edgeToNode)
  const currentIsManager = detailedEntry && detailedEntry.roles && detailedEntry.roles.indexOf(constants.POOL_ROLES.MANAGER) > -1
  const { canLeavePool } = detailedEntry || emptyObject
  const deleteModal = (
    <Modal modalType="dialog" padded={false} isOpen={!!userIdToRemove} onBackgroundClick={() => undefined} onEscapeKeydown={cancelRemovePlayer}>
      <ConfirmDialog
        title="Remove player from pool?"
        subTitle={
          <p style={{ lineHeight: "1.25rem" }}>
            Are you sure you want to remove <strong>{playerNameToRemove}</strong> from the pool? You cannot undo this action.
          </p>
        }
        onCancel={cancelRemovePlayer}
        onConfirm={confirmRemovePlayer}
        confirmText="Remove"
        loading={isLoading}
      />
    </Modal>
  )
  const showEmailModal = () => {
    setShowEmailPoolModal(true)
  }
  const hideEmailModal = () => {
    setShowEmailPoolModal(false)
  }
  const onPoolEmailSent = () => {
    if (!emailSentToast) {
      const toastId = toast.snackbar("Email sent", { position: "bottom-center", onClose: () => setEmailSendToast("") })
      setEmailSendToast(toastId)
    }
  }
  const emailPoolModal = <EmailPoolModal {...props} onInviteSent={onPoolEmailSent} isOpen={showEmailPoolModal} onClose={hideEmailModal} />
  const editPlayerBracketModal = (
    <EditPlayerBracketModal
      isOpen={(playerAvailableBrackets?.length ?? 0) > 0}
      close={() => {
        setPlayerAvailableBrackets(null)
      }}
      availableBrackets={playerAvailableBrackets ?? []}
      toggleManagerMode={toggleManagerMode}
    />
  )

  return (
    <StandardPoolPageContainer
      {...props}
      analyticsFeature="brackets"
      analyticsSubfeature={`${currentIsManager ? "manage-players" : "players"}`}
      analyticsTitle={`${currentIsManager ? "Manage Players" : "Players"}`}
    >
      <MultipleEntriesManagePlayerContent>
        <PoolPlayersHeader isManager={currentIsManager} onSearch={(text) => setSearchTerm(text)} poolId={poolId} showEmailModal={showEmailModal} />
        <StickyTableXWrapper style={stylez}>
          <MultipleEntriesManagePlayerStickyTable>
            <div className="multiple-entries-players-section-tile">
              <span className="section-title">Pool List</span>
              <span className="pagination-info">{`Total Players: ${!!!totalCount ? "-" : totalCount}`}</span>
            </div>
            <div className="multiple-entries-players-table-header">
              <span className="player-name">Name</span>
              <span className="player-email">Email</span>
              <span className="table-header-actions">
                <span className="player-is-manager">Manager</span>
              </span>
            </div>
            {members.map((member) => {
              return (
                <ManagePlayersRow
                  key={member.id}
                  currentIsManager={currentIsManager}
                  canLeavePool={canLeavePool}
                  member={member}
                  setEntryRolesMutation={setEntryRolesMutation}
                  toggleSilenceUserForPostingMutation={toggleSilenceUserForPostingMutation}
                  poolData={poolData}
                  onRemovePlayer={onRemovePlayer}
                  showIsMine={members.length > 1}
                  onMakePicksForPlayer={onMakePicksForPlayer}
                  onAfterCreateEntryForPlayer={onAfterCreateEntryForPlayer}
                />
              )
            })}
          </MultipleEntriesManagePlayerStickyTable>
        </StickyTableXWrapper>
      </MultipleEntriesManagePlayerContent>
      <PaginationNew hasPreviousPage={!!controls.after} goToPage={onGoToPage} isLoading={isLoading} {...pageInfo} />
      {deleteModal}
      {emailPoolModal}
      {editPlayerBracketModal}
    </StandardPoolPageContainer>
  )
}

export default MultipleEntriesManagePlayersWrapper
